<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>รายงานวิทยากร</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <!-- <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="รหัสคณะ/ชื่อคณะ"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field> -->
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :page="page"
                :pageCount="totalPage"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :options.sync="options"
                :loading="loading"
                :server-items-length="total"
                class="elevation-1"
                item-key="id"
                v-model="complex.selected"
              >
                <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getReportLecturer } from '@/api/report'

export default {
  name: 'ReportLecturer',
  data() {
    return {
      loginUser: null,
      loading: false,
      page: 1,
      totalPage: 0,
      total: 0,
      options: {},
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'ชื่อ',
            value: 'lecturer'
          },
          {
            text: 'คณะบรรยาย',
            value: 'group'
          },
          {
            text: 'ชั่วโมงบรรยาย',
            value: 'lecture'
          },
          {
            text: 'คะแนน',
            value: 'point'
          }
        ],
        items: [],
        actions: []
      }
    }
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    fetchData() {
      this.loading = true
      // const { page, itemsPerPage } = this.options
      // let offset = (page - 1) * itemsPerPage
      getReportLecturer()
        .then((reports) => {
          this.complex.items = reports
          // this.totalPage = options.totalPage
          // this.total = options.total
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
