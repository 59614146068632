var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-table"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("รายงานวิทยากร")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("filter_list")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.page,"pageCount":_vm.totalPage,"headers":_vm.complex.headers,"search":_vm.search,"items":_vm.complex.items,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.total,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('img',{attrs:{"src":item.avatar,"alt":"avatar","size":"16"}})])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Action")])])]}}],null,true)},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.actions),function(action){return _c('v-list-item',{key:action.text,on:{"click":function($event){return action.click(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(action.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1)]}}]),model:{value:(_vm.complex.selected),callback:function ($$v) {_vm.$set(_vm.complex, "selected", $$v)},expression:"complex.selected"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }