const Items = [
  {
    id: 1,
    lecturer: 'Banpot Srihawong',
    group: 2,
    lecture: 20,
    point: 5,
    date: '2020-10-01'
  },
  {
    id: 2,
    lecturer: 'Banpot Srihawong',
    group: 2,
    lecture: 20,
    point: 5,
    date: '2020-11-01'
  }
]

// import { fetch, fetchGet } from './fetch'
const url = '/api/v1/report'

const getReportLecturer = () => {
  // if (id === undefined) {
  //   return Promise.reject(new Error('Missing trip id'))
  // }
  // const apiUrl = `${url}/${id}`
  // return fetchGet(urlById, {})
  //   .then((trip) => {
  //     return Promise.resolve(trip)
  //   })
  //   .catch((error) => {
  //     return Promise.reject(error)
  //   })
  return Promise.resolve(Items)
}

const sortWithEnableItem = (enableArr, activities) => {
  activities.sort((a, b) => {
    return b.enableIndex == -1 ? -1 : a.enableIndex < b.enableIndex ? -1 : 1
  })
  return activities
}
export { Items, getReportLecturer, sortWithEnableItem }
